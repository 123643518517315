<template>

  <div class="domainsBlockGrid" >


    <v-row  class="domainsblocksrow" >
      <div v-for="(domain,index) in displayedItems" :key="index" v-if="domain.mainBlock !==null"  class="domainbox" >

        <div class="box4" v-if="domain.flat" v-bind:style="{background: '#000000', backgroundSize: '100%', }">
          <div class="domainclassification">
            <div class="domainprimaryclass" v-bind:style="{background: domain.mainBlock.color}">{{domain.mainBlock.description.charAt(0)+domain.mainBlock.description.slice(1).toLowerCase()}}</div></div>
          <div class="domaindesc">{{domain.description}}</div>
          <div class="domainprice">{{domain.price.toLocaleString('en-US', {style: 'currency',currency: 'EUR' ,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,})}}</div>
          <div class="actionsgrid">
            <div class="buynowbutton"  v-if="domain.forSale && domain.price>0">
              <a :href="`/checkout?`+domain.description.toLowerCase()" style="color: black">
                <div class="buttontext">Buy Now</div>
              </a>
            </div>
            <div class="buynowbutton"  v-else style="opacity: 0.5;">
              <div class="buttontext">Not for Sale</div>
            </div>

            <div class="discovermore">
              <a :href="`https://`+domain.description.toLowerCase()" style="color: white">
                <div class="buttontext">More Info</div>
              </a>
            </div>
          </div>
        </div>
        <div class="box4" v-else :style="{ background: 'linear-gradient(230.81deg, #812CC8 3.82%, #000000 36.37%)', backgroundSize: '100%' }">
          <div class="domainclassification">
            <div class="domainprimaryclass" v-bind:style="{background: domain.mainBlock.color}">{{domain.mainBlock.description.charAt(0)+domain.mainBlock.description.slice(1).toLowerCase()}}</div>
            <div class="domainsecondaryclass">Premium</div></div>
          <div class="domaindesc">{{domain.description}}</div>
          <div class="domainprice">{{domain.price.toLocaleString('en-US', {style: 'currency',currency: 'EUR',  minimumFractionDigits: 0,
            maximumFractionDigits: 0,})}}</div>
          <div class="actionsgrid">
            <div class="buynowbutton">
              <a :href="`/checkout?`+domain.description.toLowerCase()" style="color: black">
                <div class="buttontext">Buy Now</div>
              </a>
            </div>
            <div class="discovermore">
              <a :href="`https://`+domain.description.toLowerCase()" style="color: white">
                <div class="buttontext">More Info</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </v-row>
    <div class="pagination">
      <button @click="previousPage" :disabled="currentPage === 1" aria-label="left"><v-icon class="paginationicon" color="#fff">chevron_left</v-icon></button>
      <div class="page-numbers">
        <button
            v-for="pageNumber in visiblePageNumbers"
            :key="pageNumber"
            @click="goToPage(pageNumber)"
            :class="{ active: pageNumber === currentPage }"
        >
          {{ pageNumber }}
        </button>
      </div>
      <button @click="nextPage" :disabled="currentPage === totalPages" aria-label="right"><v-icon class="paginationicon" color="#fff">chevron_right</v-icon></button>
    </div>
  </div>

</template>

<script>

import request from "@/requests";
import DeleteDialog from "@/components/reusable/DeleteDialog";

const customStyles = {
  ".v-input__control": {
    border: "1px solid #3E82F1",
  },
  ".v-icon": {
    color: "#3E82F1",
  },
  ".v-select__selection--comma": {
    color: "#3E82F1",
  },
};

export default {
  name: "DomainsBlocksGrid",
  components: {DeleteDialog},

  data: () => ({
        itemsPerPage: 30,
        visiblePageCount: 7,
        currentPage: 1,


        title:"ENERG",
        description:"Refers to domain names that cover the Oil and Energy industry.",
        mainBlock:{id:"",
          description:""
        },
        defaultSelected: {
          value: "domainId",
          parameters: "mono"
        },
        domainInfo: [{
          value: "1",
          parameters: "monoverse"
        },{
          value: "2",
          parameters: "hackverse"
        },{
          value: "3",
          parameters: "babuverse"
        }],
        selectedFilter: {
          id: 1,
          description : 'Premium'
        },
        domainFilters: [
          {
            id: 1,
            description : 'Premium'
          },
          {
            id: 2,
            description : 'Offer'
          },
          {
            id: 3,
            description : 'Popularity'
          },
          {
            id: 4,
            description : 'Lowest Price'
          },
          {
            id: 5,
            description : 'Highest Price'
          },
          {
            id: 6,
            description : 'Shortest Length'
          },
          {
            id: 7,
            description : 'Longest Length'
          },
        ]


      }
  ),
  props:{
    selectedBlock: Object
  },

  methods: {

    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    goToPage(pageNumber) {
      if (pageNumber >= 1 && pageNumber <= this.totalPages) {
        this.currentPage = pageNumber;
      }
    },

    getBgImagePath(domain){
      if(domain.flat){
        return '@/assets/images/back.png'
      }else{
        return '@/assets/images/header_img.png'
      }
    },


    getDomains() {


      request.getRequest('', 'api/domains-public', '', this.getDomainsCallBack);


    },

    getDomainsCallBack(response) {
      // console.log("blockID " + this.selectedBlock.id);
      this.$store.commit('setDomainsInfo', response);
      this.$store.commit('setAllDomains', response);
      // console.log(response)
    },



    getParams(){

      this.defaultSelected.parameters = this.$route.fullPath


      let i = 0;
      for (i=0; i<this.imagenamelist.length; i++){
        if(this.imagenamelist[i].includes(this.defaultSelected.parameters.toLowerCase())){
          this.image=this.imagenamelist[i]
          this.imagemob=this.imagenamelistmob[i]
        }
      }
      return this.defaultSelected.parameters
    },




  },


  computed: {
    displayedItems() {
      // console.log("computed displayed");
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.$store.getters.domainInfo.filter(d => d.mainBlock.id === this.selectedBlock.id).slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.$store.getters.domainInfo.filter(d => d.mainBlock.id === this.selectedBlock.id).length / this.itemsPerPage);
    },
    visiblePageNumbers() {
      const middlePage = Math.ceil(this.visiblePageCount / 2);
      const startPage = Math.max(this.currentPage - middlePage, 1);
      const endPage = Math.min(startPage + this.visiblePageCount - 1, this.totalPages);

      const pageNumbers = [];
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
      return pageNumbers;
    },


  },

  watch: {

  },



  created() {

    this.getDomains()


  },

}



</script>

<style lang="css">

@import "./../../assets/styles/main.css";
@import "./../../assets/styles/mainTablet.css";
@import "./../../assets/styles/mainBigMobile.css";
@import "./../../assets/styles/mainMobile.css";
.v-text-field__input{
  color: aliceblue;

}
.v-main__wrap{
  color: aliceblue !important;
}

.v-btn__content{
  color: aliceblue;
}
.v-main__wrap{
  text-decoration-color: antiquewhite;
  background: #202020;
  color: aliceblue !important;
}
.v-list.v-select-list.v-sheet.theme--light.theme--light{
  background-color: black;
  height: 300px;
}

.v-slide-group__next--disabled, .v-slide-group__prev--disabled{
  /*display: none;*/
  /*left:16px;*/
  opacity: 0.2;
}

.v-menu__content.theme--light.menuable__content__active{
  max-height: 304px;
  /* min-width: 765px; */
  top: 12px;
  left: 12px;
  transform-origin: left top;
  z-index: 8;
  height: 300px;
}

.domainNameOnButton{
  position: absolute;
  width: 144px;
  height: 18px;
  left: 50px;
  top: 297px;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;

  color: #FFFFFF;

}
.pagination {
  height:102px!important;
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 64px;
}

.pagination button {
  /*background-color: #eaeaea;*/
  border: none;
  color: #333;
  padding: 8px 16px;
  /*margin: 0 4px;*/
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #ccc;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.pagination button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pagination button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.pagination .page-numbers {
  display: flex;
  align-items: center;
}

.pagination .page-numbers button {
  background-color: transparent;
  border: none;
  color: #333;
  padding: 0px!important;
  cursor: pointer;
}

.pagination .page-numbers button.active {
  font-weight: bold;
  text-decoration: underline;
  color: #A5A5A5;
  border-radius: 5px;
  width: 32px;
  height: 32px;
}
.pagination .page-numbers button:hover {

  background-color: #000000;
}

.v-menu__content {
  position: absolute;
  display: inline-block;
  max-width: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  contain: content;
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

div.v-menu__content.theme--light.menuable__content__active{
  overflow: hidden;
  border: thin solid;
  border-radius: 15px;
  border-color: #03DAC6;
  height: 345px;
}

div.v-select__selection.v-select__selection--comma {
  color: #03DAC6 !important;
}

</style>
