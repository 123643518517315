<template>
    <div class="becomeMonoBackground">
        <div class="headerGrid">
            <div class="become_headerGridRow_1"><h1 class="welcome" >Become a Monoversian</h1></div>
            <div class="become_headerGridRow_2"><p class="subtext" >
                Develop your business with a premium <b>.com</b> domain ending with <b>Verse, Cakes, Nomads</b> and more. As a Monoversian, gain access to <b>Monoverse’s digital assets.</b></p></div>
            <div class="become_headerGridRow_4">
                <a href="/join" class="headerJoinBtn"><div class="headerJoinBtn"><p class="headerMoreBtntext">Join Monoverse</p></div></a>
<!--                <a href="/domains" class="headerMoreBtn"><div class="headerMoreBtn"><p class="headerJoinBtntext">Discover more</p></div></a>-->
            </div>
        </div>
    </div>
</template>


<script>
    import {eventBus} from "@/main";



    export default {
        name: "header",}

</script>


<style lang="css">


    @import "./../../assets/styles/mainTablet.css";
    @import "./../../assets/styles/main.css";


    @import "./../../assets/styles/mainBigMobile.css";


</style>




