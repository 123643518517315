<template>
    <div class="blocksGridRoot" >
        <div class="exploreBlocksgrid">
            <div class="exploremonoverse">Explore all building blocks</div>
        </div>

        <div class="blockpagechips">
            <v-slide-group style="width:100%">

                <template v-slot:next>
                    <v-icon style="color: white !important; margin-top: 15px;" >chevron_right</v-icon>
                </template>
                <template v-slot:prev>
                    <v-icon style="color: white !important; margin-top: 15px;" >chevron_left</v-icon>
                </template>
                <v-slide-item v-slot:default="{ active, toggle }"
                              v-for="(chip, index) in mainBlock" :key="index"
                              autofocus
                >
                    <v-btn v-if="selectedBlockIndex === index"
                            @click="handleChipClick(index)"
                            :color="chip.color"
                            :text-color="chip.color"
                            class="b-chip"
                            :input-value="active"
                            active-class="purple white--text"
                            style="text-transform: unset !important;"


                    >

                        <span style="color: #fff">
                            {{ chip.description.charAt(0)+chip.description.slice(1).toLowerCase() }}
                        </span>

<!--                        <v-icon style="margin-left: 4px;" v-if="!!selectedBlockIndex === index">close</v-icon>-->

                    </v-btn>
                    <v-btn v-else
                           @click="handleChipClick(index)"
                           color="#fff"
                           text-color="#ffffff"
                           class="b-chip"
                           :input-value="active"
                           active-class="purple white--text"
                           style="text-transform: unset !important;"


                    >

                        <span v-bind:style="{color: chip.color}">
                            {{ chip.description.charAt(0)+chip.description.slice(1).toLowerCase() }}
                        </span>

                        <!--                        <v-icon style="margin-left: 4px;" v-if="!!selectedBlockIndex === index">close</v-icon>-->

                    </v-btn>
                </v-slide-item>
            </v-slide-group>
        </div>
      <div>
        <v-tabs vertical class="vtabvertical" v-model="selectedBlockIndex">
          <v-tab  v-for="(block,index) in mainBlock" :key="index" :style="`background-color: ` + block.color + `;`">

            {{ block.description.charAt(0)+block.description.slice(1).toLowerCase() }}
          </v-tab>


          <v-tab-item v-for="(block,index) in mainBlock" :key="block.id" class="tabs" >
              <div class="blockComponentHeader"   v-if="block.priority ===1" :style="`background-color: ` + block.color + `;`">
                  <div class="blockComponentGrid" v-bind:id="`${block.description.toLowerCase()}`">
                      <div class="blockHeadlineOne">
                          {{block.description}}
                      </div>
                      <div class="blockHeadlineTwo">
                          {{block.body}}
                      </div>
<!--                      <div class="block-img">-->

<!--&lt;!&ndash;                          style="border-style: solid;background-color: #ffffff ">&ndash;&gt;-->
<!--&lt;!&ndash;                          <div :style="{ 'background-image': 'url(@/assets/images/' + block.imageName.toLowerCase().replace('jpg','svg') + ')' }" >&ndash;&gt;-->
<!--&lt;!&ndash;                          <img  src="@/assets/images/auto.svg" >&ndash;&gt;-->
<!--                      </div>-->
                  </div>
              </div>
              <div class="blockComponentHeader"  v-else :style="`background-color: ` + block.color + `;`+ `border-radius:15px 15px 0 0px`" >
                  <div class="blockComponentGrid" v-bind:id="`${block.description.toLowerCase()}`">
                      <div class="blockHeadlineOne">
                          {{block.description}}
                      </div>
                      <div class="blockHeadlineTwo">
                          {{block.body}}
                      </div>
<!--                      <div class="block-img" style="opacity: 0.5">-->
<!--                          &lt;!&ndash;                          <div :style="{ 'background-image': 'url(@/assets/images/' + block.imageName.toLowerCase().replace('jpg','svg') + ')' }" >&ndash;&gt;-->
<!--&lt;!&ndash;                          <img  src="@/assets/images/auto.svg" >&ndash;&gt;-->

<!--                      </div>-->
                  </div>
              </div>



                <DomainsBlocksGrid  :selected-block="block" :style="`background-color: ` + block.color + `;`"/>


          </v-tab-item>

        </v-tabs>
      </div>
    </div>




</template>

<script>

    import request from "@/requests";
    import DomainsGrid from "@/components/reusable/DomainsGrid";
    import headerComponent from "@/components/reusable/header";
    import explore from "@/components/reusable/exploreGrid";
    import DomainsBlocksGrid from "@/components/reusable/DomainsBlocksGrid";



    export default {
        name: "DomainsPage",
        components: {DomainsBlocksGrid,headerComponent,explore},

        data: () => ({

                selectedFilter: {
                    id: 1,
                    description : 'Premium'
                },

              mainBlock :[],
            selectedChip: null,
              selectedBlockIndex: 0,
                domainFilters: [
                    {
                        id: 1,
                        description : 'Premium'
                    },
                    {
                        id: 2,
                        description : 'Offer'
                    },
                    {
                        id: 3,
                        description : 'Popularity'
                    },
                    {
                        id: 4,
                        description : 'Lowest Price'
                    },
                    {
                        id: 5,
                        description : 'Highest Price'
                    },
                    {
                        id: 6,
                        description : 'Shortest Length'
                    },
                    {
                        id: 7,
                        description : 'Longest Length'
                    },
                ]

            }
        ),

        methods: {


          handleChipClick(index){
            this.selectedBlockIndex = index;
              this.chipTitle = chip.description;

              if(!!this.selectedChip && this.selectedChip.id === chip.id){
                  // this.$store.commit('setDomainsInfo', this.$store.getters.allDomains)
                  this.selectedChip = null
              }else {
                  // this.$store.commit('setDomainsInfo', this.$store.getters.allDomains.filter(item => item.mainBlock.id === chip.id))
                  this.selectedChip = chip
              }
          },



          getBlock(){
            request.getRequest('', 'api/main-block', '', this.getBlockCallBack);
          },

          getBlockCallBack(response) {

            this.mainBlock=response;
          // console.log(this.mainBlock)
            let i = 0;
            for (i=0; i<this.mainBlock.length; i++){
              if (this.mainBlock[i].description.toLowerCase() === this.defaultSelected.parameters.split("/")[1].toLowerCase()){
                this.title=this.mainBlock[i].description
                this.description = this.mainBlock[i].body

              }else{
              }
            }
          },






            changeFilter(filter){
                if(filter.id === 1){

                }
                else if(filter.id === 2){

                  this.$store.getters.domainInfo.filter(item => !!item.offer)
                }
                else if(filter.id === 3){

                  this.$store.getters.domainInfo.filter(item => !!item.popular)
                }
                // lowest price
                else if(filter.id === 4){
                    this.$store.getters.domainInfo.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
                }
                // highest price
                else if(filter.id === 5){
                    this.$store.getters.domainInfo.sort((a, b) => parseFloat(a.price) - parseFloat(b.price)).reverse();
                }
                else if(filter.id === 6){
                    this.$store.getters.domainInfo.sort((a, b) => a.description.length - b.description.length);
                }
                else if(filter.id === 7){
                    this.$store.getters.domainInfo.sort((a, b) => a.description.length - b.description.length).reverse();
                }
            },
            getDomains() {


                request.getRequest('', 'api/domains-public', '', this.getDomainsCallBack);


            },

            getDomainsCallBack(response) {
                this.$store.commit('setDomainsInfo', response);
                this.$store.commit('setAllDomains', response);
                // console.log(response)
            },


        },

        mounted() {
            let path = this.$route.fullPath
            //     this.active_tab=path.split("?")[1]
            this.selectedBlockIndex = parseInt(path.split("?")[1])-1;
            // console.log(this.selectedBlockIndex)
        },
        computed: {
            displayedItems() {
                const startIndex = (this.currentPage - 1) * this.itemsPerPage;
                const endIndex = startIndex + this.itemsPerPage;
                return this.$store.getters.domainInfo.slice(startIndex, endIndex);
            },
            totalPages() {
                return Math.ceil(this.$store.getters.domainInfo.length / this.itemsPerPage);
            },
            visiblePageNumbers() {
                const middlePage = Math.ceil(this.visiblePageCount / 2);
                const startPage = Math.max(this.currentPage - middlePage, 1);
                const endPage = Math.min(startPage + this.visiblePageCount - 1, this.totalPages);

                const pageNumbers = [];
                for (let i = startPage; i <= endPage; i++) {
                    pageNumbers.push(i);
                }
                return pageNumbers;
            },

        },

        watch: {

        },



        created() {
            this.getDomains();
            this.getBlock();
            this.mounted();
        },

    }


</script>

<style lang="css">

    @import "./../../assets/styles/main.css";



    .pagination button {
        /*background-color: #eaeaea;*/
        border: none;
        color: #333;
        padding: 8px 16px;
        margin: 0 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .pagination button:hover {
        background-color: #ccc;
    }

    .pagination button:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    .pagination button:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .pagination button:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .pagination .page-numbers {
        display: flex;
        align-items: center;
    }

    .pagination .page-numbers button {
        background-color: transparent;
        border: none;
        color: #333;
        padding: 8px;
        cursor: pointer;
    }

    .pagination .page-numbers button.active {
        font-weight: bold;
        text-decoration: underline;
        color: #A5A5A5;
    }

    .v-menu__content {
        position: absolute;
        display: inline-block;
        max-width: 80%;
        overflow-y: auto;
        overflow-x: hidden;
        contain: content;
        -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
    }

    div.v-menu__content.theme--light.menuable__content__active{
        overflow: hidden;
        border: thin solid;
        border-radius: 15px;
        border-color: #03DAC6;
        height: 345px;
    }

    div.v-select__selection.v-select__selection--comma {
        color: #03DAC6 !important;
    }

    .v-tab{
        justify-content: left;
        padding: 0 24px;
    }

    div.v-tab.v-tab--active {
        position: relative;
        z-index: 2;
      width: 215px !important;
        padding-right: 38px;

      /*  width: 0px!important;*/
    }

    .v-slide-group__wrapper {
      background-color: #202020 !important;
      width: 193px;
    }
    .v-tabs-slider-wrapper {
        display: none;
        width: 0px;
    }






    .v-window-item v-window-item--active{


    }






</style>
